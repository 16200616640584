<svg
  xmlns="http://www.w3.org/2000/svg"
  aria-hidden="true"
  viewBox="0 0 128 128"
  width="100%"
  class={$$props.class || ""}
>
  <g id="_x31__stroke">
    <g id="Amazon_1_">
      <rect clip-rule="evenodd" fill="none" fill-rule="evenodd" height="128" width="128" /><g id="Amazon">
        <path
          clip-rule="evenodd"
          d="M70.849,35.607c-3.673,0.279-7.917,0.557-12.154,1.114     c-6.495,0.858-12.99,1.988-18.348,4.533c-10.453,4.239-17.52,13.282-17.52,26.556c0,16.685,10.732,25.156,24.301,25.156     c4.516,0,8.188-0.572,11.567-1.408c5.381-1.702,9.889-4.811,15.255-10.466c3.108,4.239,3.966,6.227,9.325,10.744     c1.415,0.557,2.83,0.557,3.951-0.279c3.394-2.831,9.332-7.921,12.433-10.744c1.415-1.129,1.136-2.831,0.278-4.232     c-3.101-3.96-6.216-7.356-6.216-14.984V36.164c0-10.737,0.858-20.631-7.052-27.972C80.173,2.266,69.998,0,62.088,0h-3.394     C44.297,0.836,29.05,7.055,25.648,24.862c-0.564,2.266,1.136,3.11,2.265,3.388l15.819,1.98c1.693-0.286,2.544-1.702,2.822-3.102     c1.407-6.219,6.495-9.329,12.139-9.901h1.136c3.394,0,7.067,1.416,9.039,4.247c2.258,3.388,1.979,7.913,1.979,11.874V35.607z      M67.74,69.225c-1.979,3.953-5.373,6.498-9.046,7.356c-0.557,0-1.407,0.279-2.258,0.279c-6.209,0-9.881-4.804-9.881-11.866     c0-9.05,5.366-13.282,12.139-15.27c3.673-0.843,7.917-1.129,12.154-1.129v3.396C70.849,58.488,71.127,63.571,67.74,69.225z"
          fill="#343B45"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M109.464,103.642c-0.64-0.008-1.294,0.143-1.904,0.429     c-0.685,0.271-1.385,0.587-2.047,0.866l-0.971,0.407l-1.264,0.504v0.015c-13.735,5.572-28.162,8.84-41.513,9.126     c-0.489,0.015-0.986,0.015-1.46,0.015c-20.997,0.015-38.126-9.728-55.405-19.328c-0.602-0.316-1.227-0.482-1.829-0.482     c-0.775,0-1.573,0.294-2.152,0.836C0.339,96.58-0.007,97.37,0,98.176c-0.008,1.047,0.557,2.01,1.347,2.635     C17.565,114.899,35.342,127.985,59.251,128c0.467,0,0.941-0.015,1.415-0.023c15.21-0.339,32.406-5.481,45.757-13.869l0.083-0.053     c1.746-1.047,3.492-2.236,5.14-3.554c1.024-0.76,1.731-1.95,1.731-3.185C113.332,105.126,111.473,103.642,109.464,103.642z      M127.985,95.857v-0.008c-0.06-1.333-0.339-2.349-0.896-3.192l-0.06-0.083l-0.068-0.083c-0.564-0.617-1.106-0.851-1.693-1.107     c-1.754-0.678-4.305-1.039-7.375-1.047c-2.205,0-4.636,0.211-7.082,0.745l-0.008-0.166l-2.461,0.821l-0.045,0.023l-1.392,0.452     v0.06c-1.633,0.678-3.116,1.521-4.493,2.522c-0.858,0.64-1.565,1.491-1.603,2.793c-0.023,0.708,0.339,1.521,0.933,2.003     c0.595,0.482,1.287,0.648,1.896,0.648c0.143,0,0.278-0.008,0.399-0.03l0.12-0.008l0.09-0.015     c1.204-0.256,2.958-0.429,5.012-0.715c1.761-0.196,3.627-0.339,5.246-0.339c1.144-0.008,2.175,0.075,2.882,0.226     c0.354,0.075,0.617,0.166,0.76,0.241c0.053,0.015,0.09,0.038,0.113,0.053c0.03,0.098,0.075,0.354,0.068,0.708     c0.015,1.355-0.557,3.87-1.347,6.325c-0.768,2.455-1.701,4.917-2.318,6.551c-0.151,0.376-0.248,0.791-0.248,1.242     c-0.015,0.655,0.256,1.453,0.828,1.98c0.557,0.527,1.279,0.738,1.881,0.738h0.03c0.903-0.008,1.671-0.369,2.333-0.888     c6.246-5.617,8.421-14.592,8.512-19.644L127.985,95.857z"
          fill="#FF9A00"
          fill-rule="evenodd"
        />
      </g>
    </g>
  </g>
</svg>
